interface Config {
  checkoutEndpoint: string;
  redirectUrl: string;
}

const hostname = (window && window.location && window.location.hostname) || "";

export default ((): Config => {
  if (hostname === "webshop.staging.smartum.fi") {
    return {
      checkoutEndpoint: "https://api.staging.smartum.fi/checkout",
      redirectUrl: "https://webshop.staging.smartum.fi/",
    };
  } else {
    return {
      checkoutEndpoint: "https://api.staging.smartum.fi/checkout",
      redirectUrl: "http://localhost:3000/",
    };
  }
})();
