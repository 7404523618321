import React, { useState, useEffect } from "react";
import qs from "qs";
import jwtDecode from "jwt-decode";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Navbar,
  Row,
} from "react-bootstrap";
import config from "./config";

const apiVersions = ["2020-04-02", "2018-11-13"];

type BenefitOptionType = {
  benefit: string;
  venue: string;
  heading: string;
  description: string;
  image_url: string;
};

const benefitOptions: BenefitOptionType[] = [
  {
    benefit: "culture",
    venue: "ven_3A7W547h0OaalBPm",
    heading: "Movie Ticket",
    description: "Movie ticket to enjoy some culture.",
    image_url:
      "https://images.unsplash.com/photo-1489599849927-2ee91cede3ba?fit=crop&w=300&h=300&q=80",
  },
  {
    benefit: "exercise",
    venue: "ven_ZWx9l10hrNNX37d4",
    heading: "Online Yoga",
    description:
      "With this purchase, you'll be able to do Yoga from the comfort of your living room or bedroom.",
    image_url:
      "https://images.unsplash.com/photo-1544367567-0f2fcb009e0b?fit=crop&w=300&h=300&q=80",
  },
  {
    benefit: "massage",
    venue: "ven_v2eg5OMhvq6KXnpm",
    heading: "30 min massage",
    description: "Relax and reinvigorate.",
    image_url:
      "https://images.unsplash.com/photo-1514672013381-c6d0df1c8b18?fit=crop&w=300&h=300&q=80",
  },
  {
    benefit: "commuter",
    venue: "ven_0oAW5yNh78KKDn6q",
    heading: "30 day travel card",
    description: "Valid for zones A and B.",
    image_url:
      "https://images.unsplash.com/photo-1551062999-30690990f795?fit=crop&w=300&h=300&q=80",
  },
  {
    benefit: "lunch",
    venue: "ven_LRbPDWzhbkmk5r4V",
    heading: "Lunch",
    description: "A delicious and healthy lunch, delivered right to your door.",
    image_url:
      "https://images.unsplash.com/photo-1510629954389-c1e0da47d414?fit=crop&w=300&h=300&q=80",
  },
];

const data = qs.parse(window.location.search, {
  ignoreQueryPrefix: true,
});

export default function App() {
  const [benefitOption, setBenefitOption] = useState<BenefitOptionType>(
    benefitOptions[0]
  );
  const [venueId, setVenueId] = useState("");
  const [apiVersion, setApiVersion] = useState<string>(apiVersions[0]);
  const [amount, setAmount] = useState<number>(1);
  const [stripeIntegration, setStripeIntegration] = useState<boolean>(false);
  const [stripeAccount, setStripeAccount] = useState<string>(
    "acct_1A0VQ7KMXBFli3Yv"
  );
  const [maxBenefitAmount, setMaxBenefitAmount] = useState<number>(amount);

  useEffect(() => {
    setVenueId(benefitOption.venue);
  }, [benefitOption]);

  const API_CHECKOUT_ENDPOINT = config.checkoutEndpoint;
  const REDIRECT_URL =
    data.checkoutDevPort === undefined
      ? config.redirectUrl
      : `${config.redirectUrl}?checkoutDevPort=${data.checkoutDevPort}`;

  const checkoutApiPayload = () => {
    if (apiVersion === "2020-04-02") {
      const payload: any = {
        benefit: benefitOption.benefit,
        venue: venueId || benefitOption.venue,
        heading: benefitOption.heading,
        description: benefitOption.description,
        image_url: benefitOption.image_url,
        amount: amount,
        success_url: REDIRECT_URL,
        error_url: REDIRECT_URL,
        timeout: 600,
      };
      if (stripeIntegration) {
        payload.stripe_account = stripeAccount;
        payload.max_benefit_amount = maxBenefitAmount;
      }
      return payload;
    } else {
      return {
        benefit: benefitOption.benefit,
        venue: benefitOption.venue,
        product_name: benefitOption.heading,
        product_description: benefitOption.description,
        product_image_url: benefitOption.image_url,
        amount: amount,
        success_url: REDIRECT_URL,
        cancel_url: REDIRECT_URL,
        timeout: 600,
      };
    }
  };

  const startCheckoutFlow = async () => {
    const resp = await fetch(API_CHECKOUT_ENDPOINT, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Smartum-Version": apiVersion,
      },
      body: JSON.stringify(checkoutApiPayload()),
    });
    const json = await resp.json();
    let url = json.data.url;
    if (data.checkoutDevPort !== undefined) {
      url = url.replace(
        "https://checkout.staging.smartum.fi",
        `http://localhost:${data.checkoutDevPort}`
      );
    }
    window.location = url;
  };

  const onBenefitOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let index = e.target.selectedIndex;
    setBenefitOption(benefitOptions[index]);
  };

  const onVenueIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const venueId = e.target.value;
    setVenueId(venueId);
  };

  const onApiVersionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let index = e.target.selectedIndex;
    setApiVersion(apiVersions[index]);
  };

  const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount = parseInt(e.target.value, 10);
    setAmount(amount);
    if (!stripeIntegration) {
      setMaxBenefitAmount(amount);
    }
  };

  const onStripeIntegrationChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStripeIntegration(e.target.checked);
  };

  const onStripeAccountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStripeAccount(e.target.value);
  };

  const onMaxBenefitAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMaxBenefitAmount(parseInt(e.target.value, 10));
  };

  return (
    <>
      <Navbar bg="dark" variant="dark">
        <Navbar.Brand href="#home">Smartum Webshop</Navbar.Brand>
      </Navbar>
      <Container>
        {data.jwt && (
          <Alert variant="success">
            <Alert.Heading>Success!</Alert.Heading>
            <p>
              JWT payload:
              <pre>{JSON.stringify(jwtDecode(data.jwt), null, 2)}</pre>
            </p>
          </Alert>
        )}
        <Row className="justify-content-md-center">
          <Col lg={6} md={8}>
            <Form>
              <Form.Group as={Form.Row}>
                <Form.Label column sm={3}>
                  Api Version
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    as="select"
                    custom
                    onChange={onApiVersionChange}
                  >
                    {apiVersions.map((version) => (
                      <option key={version}>{version}</option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Form.Row}>
                <Form.Label column sm={3}>
                  Amount
                </Form.Label>
                <Col sm={9}>
                  <InputGroup>
                    <Form.Control
                      value={amount}
                      type="number"
                      onChange={onAmountChange}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>cents</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Form.Group>
              <Form.Group as={Form.Row}>
                <Form.Label column sm={3}>
                  Benefit
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    as="select"
                    custom
                    onChange={onBenefitOptionChange}
                  >
                    {benefitOptions.map((o) => (
                      <option id={o.venue} key={o.venue}>
                        {o.benefit}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Form.Row}>
                <Form.Label column sm={3}>
                  Venue Id
                </Form.Label>
                <Col sm={9}>
                  <Form.Control value={venueId} onChange={onVenueIdChange} />
                  <Form.Text id="venueIdNote" muted>
                    For advanced usage
                  </Form.Text>
                </Col>
              </Form.Group>
              {apiVersion === "2020-04-02" && (
                <>
                  <Form.Group>
                    <Form.Check
                      id="stripe-integration"
                      type="switch"
                      label="Enable Stripe Integration"
                      checked={stripeIntegration}
                      onChange={onStripeIntegrationChange}
                    />
                  </Form.Group>
                  {stripeIntegration && (
                    <>
                      <Form.Group as={Form.Row}>
                        <Form.Label column sm={3}>
                          Stripe Account
                        </Form.Label>
                        <Col sm={9}>
                          <Form.Control
                            value={stripeAccount}
                            onChange={onStripeAccountChange}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Form.Row}>
                        <Form.Label column sm={3}>
                          Max Benefit
                        </Form.Label>
                        <Col sm={9}>
                          <InputGroup>
                            <Form.Control
                              value={maxBenefitAmount}
                              type="number"
                              onChange={onMaxBenefitAmountChange}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>cents</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </Col>
                      </Form.Group>
                    </>
                  )}
                </>
              )}
            </Form>
            <Button onClick={startCheckoutFlow}>
              Pay with Smartum Checkout
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
